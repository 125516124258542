@import 'assets/styles/variables.scss';


.addTFormInputs__label {
  display: block;
  width: 100%;

  .addTFormInputs__input {
    background: rgb(180, 180, 180);
    border: none;
    border-radius: 0.5rem;
    color: black;
    height: 2.5rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    width: 100%;
  }
}

.addTFormInputs__inputDate {
  min-width: calc(100% - 1rem);
} // styling to force the width to be 100% even in mobile screens

.addTFormInputs__notes {
  background: rgb(180, 180, 180);
  display: block;
  height: 7rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  resize: none;
  width: 100%;

  &::placeholder {
    color: rgb(54, 54, 54);
    font-size: 1rem;
  }
}

.addTFormInputs__button {
  background: $menuColor;
  border: none;
  border-radius: 0.5rem;
  color: white;
  height: 3rem;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
  }
}