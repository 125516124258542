@import 'assets/styles/variables';
@import './_assets/menuVars.scss';
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&display=swap');

.stheader {
  background: $menuColor;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.stheader__nav{
  align-items: center;
  display: flex;
}