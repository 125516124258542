@import 'assets/styles/variables';

.transaction {
  border-bottom: 0.2rem solid rgb(194, 194, 194);
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  &__category{
    align-items: center;
    display: flex;
    justify-content: center;
    text-transform: uppercase;

    &--icon {
      border: 0.2rem solid white;
      border-radius: 50%;
      height: 3.5rem;
      margin: 0 0.5rem;
      width: 3.5rem;
    }
    &--iconText {
      align-items: center;
      border: 0.2rem solid white;
      border-radius: 50%;
      display: flex;
      font-weight: bold;
      height: 3.5rem;
      justify-content: center;
      margin: 0 0.5rem;
      width: 3.5rem;
    }
  }

  // .main.widthConfig
  &__body {
    column-gap: 0.7rem;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 50% 50%;
    margin: 0 4%;
    row-gap: 0.3rem;
    width: 100%;
 
    &--name{
      grid-column: 1/2;
      grid-row: 1/2;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--account{
      font-size: 85%;
      grid-column: 1/2;
      grid-row: 2/3;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--amount{
      font-size: 85%;
      grid-column: 2/3;
      grid-row: 1/2;
      overflow: hidden;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &--amountPositive {
      color: $positiveMoney;
    }
    &--amountNegative {
      color: $negativeMoney;
    }

    &--date{
      font-size: 85%;
      grid-column: 2/3;
      grid-row: 2/3;
      text-align: right;
      // width: 6rem;
    }
  }
}