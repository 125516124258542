@import 'assets/styles/variables.scss';

.barChart__container{
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .barChart__bar--containerMaxSize {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 15rem;

    .barChart__bar--containerRelativeSize {
      align-items: center;
      background: rgb(0, 108, 0);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 3rem;
      position: relative;
      transition: 100ms;
      width: $barwidth;

      .barChart__bar--amountMoney {
        position: absolute;
        top: -1.5rem;
        font-size: 85%;
        
        .barChart__bar--amountMoney--moneyText100k{
          display: block;
          height: 3rem;
          overflow-x: hidden;
          text-overflow: ellipsis;
          width: $barwidth;

          &:hover {
            height: 2rem;
            overflow-x: auto;
            width: auto;
          }
        }
      }

      .barChart__bar--icon {
        border-radius: 50%;
        height: $barwidth;
        padding: 0.5rem;
        width: $barwidth;
      }

      .barChart__bar--iconText {
        align-items: center;
        display: flex;
        font-weight: bold;
        height: $barwidth;
        justify-content: center;
        padding: 0.5rem;
        width: $barwidth;
      }
    }
    .barChart__bar--containerRelativeSizeCursor {
      cursor: pointer;
    }
    .barChart__bar--containerRelativeSizeNotSelected {
      background: rgb(119, 146, 119) !important;
      transition: 100ms;
    }

  }

  .barChart__categoryName--container {
    padding: 1.5rem;
    width: $barwidth;
    height: 5rem;
    
    .barChart__categoryName--text {
      margin-left: - calc(1.5 * $barwidth);
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      transform: rotate(290deg);
      white-space: nowrap;
      width: 7rem;
    }
  }
}
