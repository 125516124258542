@import 'assets/styles/variables.scss';

.datepicker__container{
  align-items: center;
  display: flex;

  .datepicker__label {
    height: 2rem;
    line-height: 2rem;
    margin-right: 0.5rem;

    @media (max-width: 500px) {
      display: none;
    }
  }
  .datepicker__input {
    align-items: center;
    display: flex;
    height: 2rem;

    .datepicker__component--container{
      width: 7rem;

      .datepicker__component {
        background: none;
        border: none;
        color: white;
        font-weight: bold;
        width: 9rem;
      }
    }
  }
}
