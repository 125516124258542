@import 'assets/styles/variables';
@import '../../_assets/menuVars.scss';

.usermenuoptions {
  background: gray;
  color: white;
  flex-direction: column;
  font-size: $font-size;
  position: absolute;
  z-index: 1;

  @media (max-width: $smallmob) {
    min-width: calc($hpadding-mobile + 8rem);
    padding: $vpadding-mobile;
    right: calc(1rem - 1rem - $hpadding-smallmob);
    top: calc($image-size + $menupadding-mobile + 0.1rem);
  }
  @media (min-width: $smallmob) and (max-width: $mobile) {
    min-width: calc($hpadding-desksm + 4rem);
    padding: $vpadding-desktop;
    right: calc(1rem - 1rem - $hpadding-mobile);
    top: calc($image-size + $menupadding-desktop + 0.1rem);
  }
  @media (min-width: $mobile) and (max-width: $desktop_sm) {
    min-width: calc($hpadding-desksm + 4rem);
    padding: $vpadding-desktop;
    right: calc(1rem - 1rem - $hpadding-desksm);
    top: calc($image-size + $menupadding-desktop + 0.1rem);
  }
  @media (min-width: $desktop_sm)  and (max-width: $desktop_md) {
    min-width: calc($hpadding-deskmd + 4rem);
    padding: $vpadding-desktop;
    right: calc(1rem - 1rem - $hpadding-deskmd);
    top: calc($image-size + $menupadding-desktop + 0.1rem);
  }
  @media (min-width: $desktop_md){
    min-width: calc($hpadding-deskbg + 4rem);
    padding: $vpadding-desktop;
    right: calc(1rem - 1rem - $hpadding-deskbg);
    top: calc($image-size + $menupadding-desktop + 0.1rem);
  }

  .usermenuoption{
    margin-bottom: $vpadding-desktop;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .usermenuoptions__text {
    padding: 0.5rem;
    &:hover {
      cursor: pointer;
      outline: 1px solid white;
    }
  }
}


