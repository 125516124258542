@import 'assets/styles/variables';

.footer__container {
  align-items: center;
  background: $menuColor;
  display: flex;
  justify-content: space-between;
  height: 5rem;

  .footer__item { 
    color: white;
    font-size: $font-size;
    word-wrap: break-word;
    @media (max-width: 400px) { width: 11rem }
  }
}