@import 'assets/styles/variables';

.passwordreseted__container {
  margin: 0 auto;

  @media (max-width: $mobile) {
    width: 100%;
    max-width: $width-maxmobile;
  }
  @media (min-width: $mobile) and (max-width: $desktop_sm) {
    width: $width-maxtablet;
  }
  @media (min-width: $desktop_sm) and (max-width: $desktop_md) {
    width: $width-mediumdesk;
  }
  @media (min-width: $desktop_md) {
    max-width: $width-largedesk;
  }

  .passwordreseted__pagetitle {
    font-size: 1.7rem;
    font-weight: bold;
    margin: 2rem 0;
  }

  .passwordreseted__block {
    background: rgb(230, 230, 230);
    border-radius: 5px;
    padding: 1.5rem;

    .passwordreseted__blocktitle {
      font-weight: bold;
      margin-bottom: 2rem;
      text-align: center;
    }

    .passwordreseted__text{
      margin-bottom: 2rem;
    }

    .passwordreseted__login {
      display: flex;
      justify-content: center;
      text-decoration: underline;
      margin: 0.5rem 0;
      margin-top: 1.5rem;
    }

    .passwordreseted__link {
      text-align: center;
      a {
        text-decoration: underline;
      }
    }
  }
}