@import 'assets/styles/variables.scss';

.home__components {
  
  @media (min-width: $mobile) {
    display: grid;
    gap: 2rem;
    grid-template-columns:  calc(50% - 1rem) calc(50% - 1rem);
    grid-template-rows: auto auto auto;

    .home__userHeader{
      grid-column: 1/3;
      grid-row: 1/2;
    }
    .home__overview {
      grid-column: 1/3;
      grid-row: 2/3;
    }
    .home__totalsPerCategory {
      grid-column: 1/2;
      grid-row: 3/4;
      section{
        height: 32.5rem;
      }
    }
    .home__transactionsSummary {
      grid-column: 2/3;
      grid-row: 3/4;
      section{
        height: 32.5rem;
      }
    }
  }
}