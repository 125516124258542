@import 'assets/styles/variables';
@import '../../_assets/menuVars.scss';

.languageoptions {
  background: $menuColor;
  border-radius: 0.5rem;
  color: white;
  display: none;
  flex-direction: column;
  font-size: $font-size;
  position: absolute;
  width: 100%;

  @media (max-width: $mobile) {
    top: calc($image-size + $menupadding-mobile + 0.1rem);
    padding: $vpadding-mobile;
  }
  @media (min-width: $mobile) and (max-width: $desktop_sm) {
    top: calc($image-size + $menupadding-desktop + 0.1rem);
    padding: $vpadding-desktop;
  }
  @media (min-width: $desktop_sm) {
    top: calc($image-size + $menupadding-desktop + 0.1rem);
    padding: $vpadding-desktop;
  }

  &.languageoptions__statusmenu {
    display: flex;
  }
  .languageoption{
    margin-bottom: $vpadding-desktop;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .languageoptions__text {
    padding: 0.5rem;
    &:hover {
      cursor: pointer;
      outline: 1px solid white;
    }
  }
}


