@import 'assets/styles/variables';

.register__container {
  margin: 0 auto;

  @media (max-width: $mobile) {
    width: 100%;
    max-width: $width-maxmobile;
  }
  @media (min-width: $mobile) and (max-width: $desktop_sm) {
    width: $width-maxtablet;
  }
  @media (min-width: $desktop_sm) and (max-width: $desktop_md) {
    width: $width-mediumdesk;
  }
  @media (min-width: $desktop_md) {
    max-width: $width-largedesk;
  }

  .register__pagetitle {
    font-size: 1.7rem;
    font-weight: bold;
    margin: 2rem 0;
  }

  .register__block {
    background: rgb(230, 230, 230);
    border-radius: 5px;
    padding: 1.5rem;

    .register__text {
      font-weight: bold;
      margin-bottom: 1rem;
      text-align: center;
    }

    .register__label {
      display: block;
      font-weight: bold;
      margin-top: 1.5rem;
    }

    .register__input {
      border: none;
      border-radius: 0.4rem;
      height: 3.5rem;
      padding-left: 0.5rem;
      width: 100%;
    }

    .register__passwordvisibility--container {
      position: relative;

      .register__passwordvisibility--icon {
        cursor: pointer;
        height: 100%;
        position: absolute;
        right: 1rem;
      }
    }

    .register__inputalert{
      color: rgb(255, 78, 78);
      display: block;
      font-size: 80%;
      margin-top: 0.5rem;
    }

    .register__button {
      background: $menuColor;
      border: none;
      border-radius: 2rem;
      color: white;
      font-weight: bold;
      height: 4rem;
      margin-top: 1.5rem;
      width: 100%;

      &:hover {
        cursor: pointer;
      }
    }

    .register__link {
      margin-top: 1.5rem;
      text-align: center;
      a {
        text-decoration: underline;
      }
    }
  }
}