@import 'assets/styles/variables.scss';

.transactionsPage__components {

  @media (min-width: $mobile) {
    display: grid;
    gap: 2rem;
    grid-template-columns:  calc(50% - 1.5rem) calc(50% - 1.5rem);
    grid-template-rows: auto auto;

    .transactionsPage__userHeader{
      grid-column: 1/3;
      grid-row: 1/2;
    }
    .transactionsPage__totalsPerCategory {
      grid-column: 1/2;
      grid-row: 2/3;
      section {
        height: 32.5rem;
      }
    }
    .transactionsPage__transactionsSummary {
      grid-column: 2/3;
      grid-row: 2/3;
      height: 35rem;
      section {
        height: 32.5rem;
      }

      section {
        overflow-y: scroll;

        &::-webkit-scrollbar {
          background-color: $transactnosSummaryScroll-bgd;
          width: 1rem;
        }
      
        &::-webkit-scrollbar-thumb {
          background-color: $page-background;
          border-radius: 0.3rem;
        }
      }
    }
  }
}