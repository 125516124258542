@import './variables.scss';

.pages {
  background: $page-background;
  color: white;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  justify-content: space-between;
  min-height: calc(100vh - 10rem);
  padding-bottom: 1rem;

  @media (min-width: $mobile) { 
    padding-top: 2rem;
  }
}
