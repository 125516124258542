@import './variables.scss';

.pageComponents {
  background: $components-bgd;
  border-radius: 5px;
  padding: 2rem;
  
  @media (max-width: $smallmob) { 
    padding: 1rem;
    margin: $spaceBtwComponents-smallmob 0;
  }

  @media (min-width: $smallmob) and (max-width: $mobile) { 
    margin: $spaceBtwComponents-mobile 0;
  }

  @media (min-width: $mobile) and (max-width: $desktop_sm) { 
    margin: 0;
  }

  @media (min-width: $desktop_sm) and (max-width: $desktop_md) { 
    margin: 0;
  }

  @media (min-width: $desktop_md) { 
    margin: 0;
  }

  h2 {
    font-weight: 500;
    font-size: 110%;
    margin-bottom: 1.5rem;
  }
}