@import 'assets/styles/variables.scss';

.overview__graphBars__container {
  @media (max-width: $mobile) {
    .overview__graphBarsBalance {
      margin-top: 2.5rem;
    }
  }
  @media (min-width: $mobile) {
    display: grid;
    column-gap: 2rem;
    grid-template-columns:  calc(50% - 1rem) calc(50% - 1rem);
    grid-template-rows: auto;

    .overview__graphBarsIncomeExpense{
      grid-column: 1/2;
      grid-row: 1/2;
      padding-right: 1rem;
    }
    .overview__graphBarsBalance {
      grid-column: 2/3;
      grid-row: 1/2;
      padding-left: 0.5rem;
    }
  }


  .overview__graphBar {
    align-items: center;
    display: flex;
    &:first-child {
      margin-bottom: 1rem;
    }
  
    .overview__type {
      width: 11rem;
    }
  
    .overview__chart__container {
      width:  100%;
  
      .overview__chart {
        background: rgb(34, 34, 34);
        border: 0.1rem solid black;
        border-radius: 0.5rem;
        display: flex;
        height: $barwidth;
        justify-content: flex-end;
        line-height: $barwidth;
        min-width: min-content;
        padding: 0 1rem;
        transition: 200ms;
        white-space: nowrap;
      }
    }
  
    .overview__price__positive {
      color: $positiveMoney;
    }
    .overview__price__negative {
      color: $negativeMoney
    }
  }
}

