@import 'assets/styles/variables.scss';

.addPlusButton__container {
  bottom: 1rem;
  color: black;
  position:-webkit-sticky;
  position: sticky;

  //position of + button @media
  height: 6rem;
  margin-right: 1.5rem; 
  margin-top: -7rem;
  width: 6rem;
  @media (max-width: $smallmob) { 
    height: 3.5rem;
    margin-left: calc(100vw - 4.5rem - 2 * $hpadding-smallmob);
    margin-top: -4.75rem;
    width: 3.5rem;
  }
  @media (min-width: $smallmob) and (max-width: $mobile) { 
    height: 5rem;
    margin-left: calc(100vw - 6rem - 2 * $hpadding-mobile);
    margin-top: -6rem;
    width: 5rem;
  }
  @media (min-width: $mobile) and (max-width: $desktop_sm) { 
    margin-left: calc(100vw - 7.5rem - 2 * $hpadding-desksm);
  }
  @media (min-width: $desktop_sm) and (max-width: $desktop_md) { 
    margin-left: calc(100vw - 7.5rem - 2 * $hpadding-deskmd);
  }
  @media (min-width: $desktop_md) { 
    margin-left: calc(100vw - 7.5rem - 2 * $hpadding-deskbg);
  }

  .addPlusButton__button {
    background: $addTransactionButton-bgd;
    border: 0.15rem solid $positiveMoney;
    border-radius: 50%;
    color: $positiveMoney;
    transition: 200ms;
    
    // size of + button @media
    height: 5rem;
    width: 5rem;
    @media (max-width: $smallmob) {
      height: 3rem;
      width: 3rem;
    }
    @media (min-width: $smallmob) and (max-width: $mobile) {
      height: 4rem;
      width: 4rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .addPlusButton__button-x{
    transition: 200ms;
    transform: rotate(45deg);
  }

  .addPlusButton__button-position{
    display: none;
  }
}