@import 'assets/styles/variables.scss';

.addCategoryPage__container {
  section {
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $smallmob) and (max-width: $mobile) {
      margin-bottom: 2rem;
      max-width: 40rem;
      width: 40rem;
    }
     
    @media (min-width: $mobile) {
      margin-bottom: 2rem;
      max-width: 50rem;
      width: 50rem;
    }
  }
}