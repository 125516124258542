@import 'assets/styles/variables.scss';

.totalsPerCategory__container {
  padding-right: 3.2rem !important;
  position: relative;

  .totalsPerCategory__typeTransactionLabel {
    position: absolute;
    right: 1.5rem;
    @media (max-width: $smallmob) {
      right: 0.5rem;
      top: 1.0rem;
    }

    .totalsPerCategory__typeTransactionSelect {
      background: $typeTransactionDropMenu-bgd;
      border: 0.05rem solid white;
      border-radius: 0.5rem;
      cursor: pointer;
      color: white;
      padding: 0.5rem;
      @media (max-width: $smallmob) {padding: 0;}
    }
  }
  
  .totalsPerCategory__chart {
    display: flex;
    gap: 0 1.5rem;
    padding-top: 5rem;
    overflow-x: auto;
    padding: 2.5rem 0 2rem 1.2rem;
  
    &::-webkit-scrollbar {
      background-color: $typeTransactionScroll-bgd;
      width: 0.5rem;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $page-background;
      border-radius: 0.1rem;
    }
  }
}