.selectCategory {
  display: block;
  width: 100%;

  &__select {
    background: rgb(180, 180, 180);
    border: none;
    border-radius: 0.5rem;
    color: black;
    height: 2.5rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    width: 100%;
  }

  &__categoryDescription {
    font-size: 90%;
    margin-bottom: 1rem;
    margin-left: 0.25rem;
    margin-top: -0.75rem;
  }

  &__categoryDeletedAlert {
    color: rgb(229, 114, 114);
    font-size: 90%;
    line-height: 1.4rem;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
  }
}
