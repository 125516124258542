@import 'assets/styles/variables.scss';

.userAccount__container {
  align-items: center;
  background: $page-background;
  border-radius: 0.5rem;
  display: flex;
  gap: 1rem;
  padding: 0.75rem;

  .userAccount__nameAndType--container {
    display: flex;
    flex-direction: column;
    
    flex: 1;
    width: 0;
    .userAccount__nameAndType--name {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .userAccount__nameAndType--type {
      font-size: 80%;
      margin-left: 0.5rem;
      white-space: nowrap;
    }
  }

  .userAccount__buttons {
    display: flex;
    justify-content: flex-end;

    .userAccount__button {
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
