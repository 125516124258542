@import '../_assets/menuVars.scss';
@import 'assets/styles/variables.scss';

.stlogo {
  align-items: center;
  display: flex;
}

.stlogo__img{
  margin-right: 0.5rem;
  height: $image-size;

  @media (max-width: 330px) {
    display: none;
  }
}

.stlogo__txt {
  color: white;
  font-family: 'Comfortaa', cursive;
  font-size: 2rem;
  font-weight: 700;
}