@import 'assets/styles/variables.scss';

.userheader__container {
  background: none !important;
  border: none !important;

  @media (max-width: $smallmob) { 
    margin: $spaceBtwComponents-smallmob 0;
    padding: 0 1rem !important; 
  }
  @media (min-width: $smallmob) and (max-width: $mobile) { 
    margin: $spaceBtwComponents-mobile 0;
    padding: 0 1rem !important; 
  }
  @media (min-width: $mobile) and (max-width: $desktop_sm) { 
    margin: 0;
    padding: 0 1rem !important; 
  }
  @media (min-width: $desktop_sm) and (max-width: $desktop_md) { 
    margin: 0;
    padding: 0 1rem !important; 
  }
  @media (min-width: $desktop_md) { 
    margin: 0;
    padding: 0 1rem !important; 
  }

  .userheader__nav {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
  }
}