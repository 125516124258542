@import 'assets/styles/variables.scss';

.receipt__background {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  
  .receipt__container {
    background: rgb(255, 239, 200);
    border-radius: 0.5rem;
    color: black;
    max-width: 90%;
    padding: 2.5rem 1.5rem;
    padding-bottom: 1rem;
    position: relative;
    width: 30rem;
    height: auto !important;

    .receipt__closebutton {
      cursor: pointer;
      height: 2.5rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 2.5rem;
    }

    .receipt__title {
      font-weight: bold;
      font-size: 1.7rem;
      margin-bottom: 2rem;
      text-align: center;
    }

    .receipt__list {

      .receipt__type, .receipt__description {
        font-size: 105%;
        text-align: center;
      }
      .receipt__description {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
      }

      .receipt__amount, .receipt__category, .receipt__account, .receipt__date, .receipt__notes {
        border-bottom: 0.05rem dashed rgb(178, 178, 178);
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        text-overflow: ellipsis;
        span {
          font-weight: bold;
        }
        .receipt__categorySpan, .receipt__accountSpan{
          font-weight: normal !important;
          text-align: end;
        }
      }
      .receipt__notes {
        span{
          margin-right: 0.5rem
        }
      }

      .receipt__publishDate {
        margin-bottom: 0.5rem;
        margin-top: 2.5rem;
      }
      .receipt__id, .receipt__publishDate {
        text-align: center;
      }
    }

    .receipt__buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 2.5rem;

      .receipt__button {
        background: none;
        border: none;
        color: rgb(71, 71, 255);
        font-weight: bold;
        margin-left: 1rem;
        text-decoration: underline;
      }
    }
  }
}