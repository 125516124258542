// page padding
$hpadding-smallmob: 1rem;
$hpadding-mobile: 1.5rem;
$hpadding-desksm: 5rem;
$hpadding-deskmd: 12rem;
$hpadding-deskbg: 20rem;

// page theme
$page-background: rgb(0, 0, 31);
$spaceBtwComponents-smallmob: 1rem;
$spaceBtwComponents-mobile: 2rem;
$spaceBtwComponents-desksm: 2rem;
$spaceBtwComponents-deskmd: 2rem;
$spaceBtwComponents-deskbg: 2rem;

// page components theme
$components-bgd: rgb(34, 34, 82);

// bgd
$addTransactionButton-bgd: $components-bgd;
$typeTransactionDropMenu-bgd: $components-bgd;
$typeTransactionScroll-bgd: $components-bgd;
$typeSelectedForm-bgd: $components-bgd;
$transactnosSummaryScroll-bgd: $components-bgd;

// colors
$positiveMoney: rgb(0, 255, 0);
$negativeMoney: rgb(255, 90, 90);
$menuColor: #4d4d4d;

// charts
$barwidth: 3rem;

// font-size
$font-size: 1.3rem;
$font-size-h1: 2.5rem;
$font-weight-h1: 700;

// breakpoints
$smallmob: 450px;
$mobile: 768px;
$desktop_sm: 980px;
$desktop_md: 1280px;

// login containers
$width-maxmobile: 45rem;
$width-maxtablet: 60rem;
$width-mediumdesk: 80rem;
$width-largedesk: 90rem;

//to use in the components
// @import 'assets/styles/variables.scss';
// @media (max-width: $smallmob) {}
// @media (min-width: $smallmob) and (max-width: $mobile) {}
// @media (min-width: $mobile) and (max-width: $desktop_sm) {}
// @media (min-width: $desktop_sm) and (max-width: $desktop_md) {}
// @media (min-width: $desktop_md) {}