@import 'assets/styles/variables.scss';

.popup__container{
  background: rgb(52, 52, 52);
  border: 0.3rem solid black;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  position: absolute;

  // position according to + button
  right: 5.5rem;
  bottom: 5.5rem;
  @media (max-width: $smallmob) { 
    right: 3.25rem;
    bottom: 3.25rem;
  }
  @media (min-width: $smallmob) and (max-width: $mobile) { 
    right: 4.5rem;
    bottom: 4.5rem;
  }

  .popup__option {
    align-items: center;
    color: white;
    cursor: pointer;
    display: flex;
    white-space: nowrap;

    .popup__option--icon {
      height: 2rem;
      margin-right: 0.5rem;
      width: 2rem;
    }
  }
}
