@import 'assets/styles/variables.scss';

.addAccountForm__container {
  position: relative;

  .addAccountForm__returnPage {
    border-radius: 50%;
    cursor: pointer;
    height: 2.5rem;
    position: absolute;
    right: 2.5rem;
    top: 1rem;
    width: 2.5rem;
  }

  .addAccountForm__cancelUpdate {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 0;
    text-decoration: underline;
  }

  .addAccountForm__numberSignOptions{
    background: $page-background;
    border-radius: 0.5rem;
    display: flex;
    gap: 0.25rem;
    margin-top: 0.5rem ;
    margin-bottom: 0.4rem;
    padding: 0.25rem;
    width: min-content;

    .addAccountForm__numberSign{
      white-space: nowrap;
      transition: 100ms;
      font-size: 70%;
      padding: 0.5rem 1.5rem;
      cursor: pointer;
    }
    .addAccountForm__numberSignSelected{
      transition: 100ms;
      background: $typeSelectedForm-bgd;
      border-radius: 0.5rem;
    }
  }
  
  .addAccountForm__labels{
    display: block;
    width: 100%;
  }

  .addAccountForm__inputs, .addAccountForm__textareas, .addAccountForm__selects{
    background: rgb(180, 180, 180);
    border: none;
    border-radius: 0.5rem;
    color: black;
    height: 2.5rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    width: 100%;
  }

  .addAccountForm__accountErrorMsg {
    color: red;
    font-weight: 400;
    display: block;
    text-decoration: none;
    margin-bottom: 1rem;
    margin-top: -0.5rem;
  }

  .addAccountForm__typeDescription {
    font-size: 90%;
    margin-bottom: 1rem;
    margin-left: 0.25rem;
    margin-top: -0.75rem;
  }

  .addAccountForm__textareas{
    height: 7rem;
    resize: none;

    &::placeholder {
      color: rgb(54, 54, 54);
    }
  }

  .addAccountForm__button {
    background: $menuColor;
    border: none;
    border-radius: 0.5rem;
    color: white;
    height: 3rem;
    width: 100%;
  }
}